import * as React from 'react';
import Layout from '../components/Layout/Layout';
import * as styles from './index.module.scss';
// import { StaticImage } from 'gatsby-plugin-image';
import { IconContext } from 'react-icons';
import { Heading } from '../components/Heading/Heading';
import { Text } from '../components/Text/Text';
import Seo from '../components/Seo/Seo';
import clsx from 'clsx';
import { HiOutlineChevronDoubleRight as Chevron } from 'react-icons/hi';
import { Link } from 'gatsby';
import { BsArrowRightCircle as ArrowButton } from 'react-icons/bs';

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title={`Déboucheur de canalisation professionnel | Lège-Cap-Ferret (33236 /33950) | Fabrice Anton, Bassin Pro Service`}
        description={`Bassin Pro Services est une entreprise de débouchage de canalisations, d'hydrocurage professionnel à votre service, qui répare et entretien vos canalisations avec un hydrocureur haute pression, restaure l'écoulement des eaux usées pour particuliers et professionnel. Bassin Pro Services opère dans les villes de: Audenge, Lanton, Cassy, Taussat, Andernos-les-Bains, Arès, Lège, Lège-Cap-Ferret, Claouey, Piquey, L'Herbe, Cap-Ferret, Lacanau, Blagon, Le Temple`}
        lang={'fr'}
      />

      <div className='text-center'>
        <div className={styles.hero}>
          <div
            className={clsx({
              [`container`]: true,
            })}
          >
            {/* ============== */}
            <section
              className={clsx({
                [styles.banner]: true,
                ['row']: true,
              })}
            >
              <div className='col-12'>
                <Heading
                  tagName={'h2'}
                  styleType={'h2'}
                  textAlignment={'center'}
                  textColour={'white'}
                >
                  NOS SERVICES
                </Heading>
              </div>
            </section>
            <div className='row'>
              <div className='col-12'>
                <div className={styles.breadcrumb}>
                  <Link to='/' className={styles.linkBreadcrumb}>
                    BASSIN PRO SERVICES
                  </Link>
                  <span className={styles.chevron}>
                    <IconContext.Provider
                      value={{ size: '1em', color: '#15acdb' }}
                    >
                      <Chevron title='Icon intervention débouchage des conduits souterrains' />
                    </IconContext.Provider>
                  </span>
                  <span className={styles.textBreadcrumb}>SERVICES</span>
                </div>
              </div>
            </div>
          </div>
          {/* <StaticImage
            src='../images/hero-image-lavabo-2.jpg'
            alt='Un systeme de canalisation entretenu par un professionnel Fabrice Anton'
          /> */}
        </div>
      </div>
      <div className='container'>
        {/* ============== */}
        <section className='row justify-content-center' id='services'>
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'Bassin Pro Services, pour vous'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Débouchage de vos canalisations du Nord Bassin au Sud Médoc`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Fabrice Anton, déboucheur de canalisations et hydrocureur, se
              charge de vos canalisations bouchées à votre domicile, votre
              résidence de vacances, chez vos locataires saisonniers, mais aussi
              dans votre entreprise, votre restaurant ou votre commerce.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Spécialiste d’assainissement pour particuliers et professionnels,
              nous intervenons pour le débouchage et le décongestionnement de
              vos canalisations et réseaux domestiques : wc, évier, douche.
              Notre entreprise d’assainissement intervient 24h/24 & 7j/7 du Nord
              Bassin au Sud Médoc.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Bassin Pro Services travaille dans de nombreuses communes et
              villes dans la proximité de Lège-Cap-Ferret :
            </Text>
            <ul className={styles.bulletListCities}>
              <li>Audenge</li>
              <li>Lanton</li>
              <li>Cassy</li>
              <li>Taussat</li>
              <li>Andernos-les-Bains</li>
              <li>Arès</li>
              <li>Lège</li>
              <li>Lège-Cap-Ferret</li>
              <li>Claouey</li>
              <li>Le Piquey</li>
              <li>L'Herbe</li>
              <li>Cap-Ferret</li>
              <li>Blagon</li>
              <li>Le Temple</li>
              <li>Lacanau</li>
              <li>Le Porge</li>
            </ul>
            <Text tagName={'p'} styleType={'h5'} fontWeight='bold'>
              Votre commune n'apparaît pas ?
              <br />
              Contactez-nous directement, Bassin Pro Services fera son maximum
              pour l'hydrocurage de votre réseau d'assainissement.
            </Text>
            <Link to='/contact' className={styles.linkButton}>
              <span className={styles.label}>Nous contacter</span>
              <IconContext.Provider value={{ size: '1.5em', color: '#FFF' }}>
                <ArrowButton title='Icon intervention débouchage de canalisations et des conduits souterrains' />
              </IconContext.Provider>
            </Link>
          </div>
        </section>
        {/* ============== */}
        <section className='row justify-content-center' id='services'>
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={
                  'Nous venons chez vous avec tout notre matériel professionnel'
                }
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Intervention à domicile pour déboucher vos canalisations`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              L’eau de votre évier s’écoule mal ? L'évacuation de votre douche
              ne fonctionne plus correctement ? Vos toilettes sont inutilisables
              ? Vous êtes confrontés à une canalisation obstruée et bouchée.
              Pour vous, nous avons la solution adaptée.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Nous intervenons de manière personnalisée en tenant compte de
              l'installation de votre réseau d'évacuation et de la source
              spécifique du problème dans vos canalisations. Notre expertise et
              notre matériel nous permettent de cibler le bouchon rapidement et
              de vous proposer des solutions adaptées.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              En fonction de la profondeur et de la taille du bouchon, nous
              procédons avec des techniques différentes. Sur tous nos
              déplacements, nous venons avec tout le matériel nécessaire vous
              assurant de rétablir l'écoulement optimal de vos canalisations.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Nous sommes prêts à intervenir sur tous types de problèmes.
            </Text>
          </div>
        </section>
        {/* ============== */}
        <section className='row justify-content-center' id='services'>
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'Un diagnostic rapide et précis'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Une inspection grâce à notre caméra`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Un bouchon qui obstrue vos canalisations et empêche l'écoulement
              de vos eaux usées peut se former à tout niveau. Pour restaurer une
              évacuation parfaite, nous le localisons dans votre réseau de façon
              précise.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Notre hydrocureur est doté d'une caméra que nous branchons à
              l'extrémité de nos tuyaux, nous disposons ainsi d'une très grande
              longueur pour inspecter vos canalisations et identifier l'origine
              du problème.
            </Text>
          </div>
        </section>
        {/* ============== */}
        <section className='row justify-content-center' id='services'>
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'La restitution du débit optimal de vos canalisations'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Notre hydrocureur dernière génération`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Après avoir localisé les bouchons, nous injectons de l'eau douce à
              très haute pression avec différentes cadences et une variation de
              puissance qui nous permet de désagréger et éliminer l'encombrement
              de vos canalisations.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Nous disposons de nombreux raccords et pouvons procéder à
              l'hydrocurage de vos canalisations sur une longueur supérieure à
              50 mètres, sans perdition ni affaiblissement de la pression que
              nous exerçons sur les bouchons situés dans vos canalisations.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Nous n'utilisons aucun produit chimique et optimisons la quantité
              d'eau que nous utilisons, notre contrat est simple, nous réparons
              vos canalisations le plus rapidement possible en tenant compte de
              notre environnement.
            </Text>
            <Link to='/contact' className={styles.linkButton}>
              <span className={styles.label}>Prendre rendez-vous</span>
              <IconContext.Provider value={{ size: '1.5em', color: '#FFF' }}>
                <ArrowButton title='Icon intervention débouchage des conduits souterrains' />
              </IconContext.Provider>
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
